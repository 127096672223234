import HttpFactory from '../../factory'
import { CUSTOMERS } from '~/constants/apiPaths/customers/paths'
import type { ISearchableCustomerFields } from '~/repository/modules/customers/types/customerTypes'
import type { IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import removeEmpty from '~/helpers/helpers'
import type { ICustomerAsPayload } from '~/store/customers/types/customerTypes'

class CustomerModule extends HttpFactory {
  async getAll(
    extras: IExtrasFetch,
    searchCriteria: ISearchableCustomerFields,
  ): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>(
      'GET',
      `${this.hostname}${CUSTOMERS}?${new URLSearchParams(urlParams)}`,
      null,
      extras,
    )
  }

  async create(
    customer: ICustomerAsPayload,
    extras: IExtrasFetch,
  ): Promise<object> {
    // @ts-ignore
    delete customer.Id

    return await this.call<object>(
      'POST',
      `${this.hostname}${CUSTOMERS}`,
      customer,
      extras,
    )
  }

  async update(
    customer: ICustomerAsPayload,
    extras: IExtrasFetch,
  ): Promise<object> {
    return await this.call<object>(
      'PUT',
      `${this.hostname}${CUSTOMERS}`,
      customer,
      extras,
    )
  }

  async delete(extras: IExtrasFetch, customerId: number): Promise<object> {
    return await this.call<object>(
      'DELETE',
      `${this.hostname}${CUSTOMERS}`,
      {
        Id: customerId,
      },
      extras,
    )
  }

  /* async create(account: ICreateAccountInput): Promise<ICreateAccountResponse> {
      return await this.call<ICreateAccountResponse>('POST', `${this.RESOURCE}/register`, account);
  } */
}

export default CustomerModule
