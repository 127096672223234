import HttpFactory from '../../factory'
import { type IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import removeEmpty from '~/helpers/helpers'
import { type ISearchableAttributeValueFields } from '~/repository/modules/attributeValue/types/attributeValueTypes'
import { ATTRIBUTE_VALUES } from '~/constants/apiPaths/attributeValues/paths'
import { type IAttributeValuePayload } from '~/store/attributeValues/types/attributeValuesTypes'

class AttributeValuesModule extends HttpFactory {
  async getAll(
    extras: IExtrasFetch,
    searchCriteria: ISearchableAttributeValueFields,
    signal?: Nullable<AbortSignal>,
  ): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>(
      'GET',
      `${this.hostname}${ATTRIBUTE_VALUES}?${new URLSearchParams(urlParams)}`,
      null,
      extras,
      signal,
    )
  }

  async create(
    attributeValue: IAttributeValuePayload,
    extras: IExtrasFetch,
  ): Promise<object> {
    // @ts-ignore
    delete attributeValue.Id

    return await this.call<object>(
      'POST',
      `${this.hostname}${ATTRIBUTE_VALUES}`,
      attributeValue,
      extras,
    )
  }

  async update(
    attributeValue: IAttributeValuePayload,
    extras: IExtrasFetch,
  ): Promise<object> {
    return await this.call<object>(
      'PUT',
      `${this.hostname}${ATTRIBUTE_VALUES}`,
      attributeValue,
      extras,
    )
  }

  async delete(extras: IExtrasFetch, subCategoryId: number): Promise<object> {
    return await this.call<object>(
      'DELETE',
      `${this.hostname}${ATTRIBUTE_VALUES}`,
      {
        Id: subCategoryId,
      },
      extras,
    )
  }
}

export default AttributeValuesModule
