import HttpFactory from '../../factory'
import { IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import removeEmpty from '~/helpers/helpers'
import { ISearchableWarehouseFields } from '~/repository/modules/warehouse/types/warehouseTypes'
import { WAREHOUSES } from '~/constants/apiPaths/warehouses/paths'
import { IWarehousePayload } from '~/store/warehouses/types/warehouseTypes'

class WarehousesModule extends HttpFactory {
  async getAll(
    extras: IExtrasFetch,
    searchCriteria: ISearchableWarehouseFields,
    signal?: Nullable<AbortSignal>,
  ): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>(
      'GET',
      `${this.hostname}${WAREHOUSES}?${new URLSearchParams(urlParams)}`,
      null,
      extras,
      signal,
    )
  }

  async create(
    warehouse: IWarehousePayload,
    extras: IExtrasFetch,
  ): Promise<object> {
    // @ts-ignore
    delete warehouse.Id

    return await this.call<object>(
      'POST',
      `${this.hostname}${WAREHOUSES}`,
      warehouse,
      extras,
    )
  }

  async update(
    warehouse: IWarehousePayload,
    extras: IExtrasFetch,
  ): Promise<object> {
    return await this.call<object>(
      'PUT',
      `${this.hostname}${WAREHOUSES}`,
      warehouse,
      extras,
    )
  }

  async delete(extras: IExtrasFetch, warehouseId: number): Promise<object> {
    return await this.call<object>(
      'DELETE',
      `${this.hostname}${WAREHOUSES}`,
      {
        Id: warehouseId,
      },
      extras,
    )
  }
}

export default WarehousesModule
