import HttpFactory from '~/repository/factory'
import { type IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import { INVOICES_PAYMENT } from '~/constants/apiPaths/invoicePayments/paths'
import { type IUpdateInvoicePaymentPayload } from '~/store/invoicePayments/types/invoicePaymentTypes'

class InvoiceModule extends HttpFactory {
   async updatePayPayment(
    invoicePayment: IUpdateInvoicePaymentPayload,
    extras: IExtrasFetch,
  ): Promise<object> {
    return await this.call<object>(
      'PUT',
      `${this.hostname}${INVOICES_PAYMENT}`,
      invoicePayment,
      extras,
    )
  }


}
export default InvoiceModule
