import validate from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/node_modules/nuxt/dist/pages/runtime/validate.js";
import check_45user_45is_45logged_45in_45global from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/middleware/check-user-is-logged-in.global.ts";
import manifest_45route_45rule from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  check_45user_45is_45logged_45in_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "check-user-is-not-logged-in": () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/middleware/check-user-is-not-logged-in.ts")
}