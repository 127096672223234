import revive_payload_client_4sVQNw7RlN from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import Vue3Lottie_client_bMZiOS6AB0 from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/plugins/Vue3Lottie.client.ts";
import api_GFfDXud5Cr from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/plugins/api.ts";
import maska_UHaKf2z1iQ from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/plugins/maska.ts";
import middleware_store_initialization_vQuYDocdBB from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/plugins/middleware-store-initialization.ts";
import vuetify_7h9QAQEssH from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  Vue3Lottie_client_bMZiOS6AB0,
  api_GFfDXud5Cr,
  maska_UHaKf2z1iQ,
  middleware_store_initialization_vQuYDocdBB,
  vuetify_7h9QAQEssH
]