// packages
import { defineStore } from 'pinia'
// constants
import { AUTH_STORE } from '~/constants/stores/stores'
// store components
import { getters } from '~/store/auth/getters'
import { actions } from '~/store/auth/actions'
import type { IUserPayload } from '~/store/users/types/userTypes'

const state = () => ({
  token: '',
  user: <IUserPayload | null>null,
})

export const useAuthStore = defineStore(AUTH_STORE, {
  state,
  actions,
  getters,
  persist: {
    storage: persistedState.localStorage,
  },
})
