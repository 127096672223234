export enum Routes {
  Root = '/',
  Dashboard = '/dashboard',
  Login = '/login',
  forgotPassword = '/forgot-password',
  customerCompanies = '/customers/companies/',
  invoices = '/invoices',
}

export enum ImagePaths {}
