import HttpFactory from '../../factory'
import type { IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import removeEmpty from '~/helpers/helpers'
import type { ISearchableCompanyFields } from '~/repository/modules/company/types/companyTypes'
import { COMPANIES } from '~/constants/apiPaths/companies/paths'
import type { ICompanyPayload } from '~/store/companies/types/companyTypes'

class CompaniesModule extends HttpFactory {
  async getAll(
    extras: IExtrasFetch,
    searchCriteria: ISearchableCompanyFields,
    signal?: Nullable<AbortSignal>,
  ): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>(
      'GET',
      `${this.hostname}${COMPANIES}?${new URLSearchParams(urlParams)}`,
      null,
      extras,
      signal,
    )
  }

  async getById(
    extras: IExtrasFetch,
    id: number,
    signal?: Nullable<AbortSignal>,
  ): Promise<object> {
    return await this.call<object>(
      'GET',
      `${this.hostname}${COMPANIES}/${id}`,
      null,
      extras,
      signal,
    );
  }

  async create(
    company: ICompanyPayload,
    // company: FormData,
    extras: IExtrasFetch,
  ): Promise<object> {
    // @ts-ignore
    delete company.Id

    return await this.call<object>(
      'POST',
      `${this.hostname}${COMPANIES}`,
      company,
      extras,
    )
  }

  async update(
    company: ICompanyPayload,
    extras: IExtrasFetch,
  ): Promise<object> {
    return await this.call<object>(
      'PUT',
      `${this.hostname}${COMPANIES}`,
      company,
      extras,
    )
  }

  async delete(extras: IExtrasFetch, companyId: number): Promise<object> {
    return await this.call<object>(
      'DELETE',
      `${this.hostname}${COMPANIES}`,
      {
        Id: companyId,
      },
      extras,
    )
  }
}

export default CompaniesModule
