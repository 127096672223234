import HttpFactory from '../../factory'
import { AUTH, LOGIN } from '~/constants/apiPaths/auth/paths'
import { ILoginCredentials } from '~/repository/modules/auth/types/loginTypes'

class AuthModule extends HttpFactory {
  async login(credentials: ILoginCredentials): Promise<object> {
    return await this.call<object>(
      'POST',
      `${this.hostname}${AUTH}${LOGIN}`,
      credentials,
    )
  }

  /* async create(account: ICreateAccountInput): Promise<ICreateAccountResponse> {
        return await this.call<ICreateAccountResponse>('POST', `${this.RESOURCE}/register`, account);
    } */
}

export default AuthModule
