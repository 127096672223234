import { Status } from '~/constants/general/Status'
export const useLoginStatus = () => {
  const statusLogin = useState(() => Status.INITIAL)
  function changeLoginStatus(toStatus: Status) {
    statusLogin.value = toStatus
  }

  return {
    statusLogin,
    changeLoginStatus,
  }
}
