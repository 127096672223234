import { useAuthStore } from '~/store/auth/auth'
import { Routes } from '~/constants/routes/Routes'


export default defineNuxtRouteMiddleware(async (_to, _from) => {
  const authStore = useAuthStore()

  if (process.server) {
    return
  }
  /* if (authStore.token !== null && authStore.token.length > 0) {
    const pathToGo: string = _to.path === Routes.Root || _to.path === Routes.Login ? Routes.Dashboard : _to.path;
    return navigateTo({ path: pathToGo })
  } else if(_to.path !== Routes.Login) {
    return navigateTo({ path: Routes.Login })
  } */
  if (authStore.token.length <= 0) {
    if (_to.path !== Routes.Login && _to.path !== Routes.forgotPassword) {
      return navigateTo({ path: Routes.Login })
    }
  } else {
    if(_to.path === Routes.Login){
      return navigateTo({ path: Routes.Dashboard })
    }
    if(_to.path === Routes.Root){
      return navigateTo({ path: Routes.Dashboard })
    }
  }
})
