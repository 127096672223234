import HttpFactory from '../../factory'
import { type IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import removeEmpty from '~/helpers/helpers'
import { type ISearchableUserFields } from '~/repository/modules/user/types/userTypes'
import { FORGOT_PASSWORD, RESET_PASSWORD, USERS } from '~/constants/apiPaths/users/paths'
import type { IForgotPasswordPayload, IResetPasswordPayload, IUserPayload } from '~/store/users/types/userTypes'

class UsersModule extends HttpFactory {
  async getAll(
    extras: IExtrasFetch,
    searchCriteria: ISearchableUserFields,
    signal?: Nullable<AbortSignal>,
  ): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>(
      'GET',
      `${this.hostname}${USERS}?${new URLSearchParams(urlParams)}`,
      null,
      extras,
      signal,
    )
  }

  async create(user: IUserPayload, extras: IExtrasFetch): Promise<object> {
    // @ts-ignore
    delete user.Id

    return await this.call<object>(
      'POST',
      `${this.hostname}${USERS}`,
      user,
      extras,
    )
  }

  async update(user: IUserPayload, extras: IExtrasFetch): Promise<object> {
    return await this.call<object>(
      'PUT',
      `${this.hostname}${USERS}`,
      user,
      extras,
    )
  }

  async changePassword(resetPasswordPayload: IResetPasswordPayload, extras: IExtrasFetch, signal?: Nullable<AbortSignal>,): Promise<object> {
    return await this.call<object>(
      'POST',
      `${this.hostname}${USERS}${RESET_PASSWORD}`,
      resetPasswordPayload,
      extras,
      signal
    )
  }

  async forgotPassword(resetPasswordPayload: IForgotPasswordPayload, extras: IExtrasFetch, signal?: Nullable<AbortSignal>,): Promise<object> {
    return await this.call<object>(
      'POST',
      `${this.hostname}${USERS}${FORGOT_PASSWORD}`,
      resetPasswordPayload,
      extras,
      signal
    )
  }

  async delete(extras: IExtrasFetch, userId: number): Promise<object> {
    return await this.call<object>(
      'DELETE',
      `${this.hostname}${USERS}`,
      {
        Id: userId,
      },
      extras,
    )
  }
}

export default UsersModule
