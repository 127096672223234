/* import { useAuthStore } from '~/store/auth/auth'
import { Routes } from '~/constants/routes/Routes'
*/
export default defineNuxtPlugin((_nuxt) => {
  // @ts-ignore
  // const authStore =  useAuthStore(nuxt.$pinia)
  // The pinia or vuex instant is needed in order to access the store instance outside setup()
  addRouteMiddleware(
    'auth-middleware',
    async (_to, _from) => {
      /* if (authStore.token && authStore.token.length > 0) {
        const pathToGo: string | null =
          _to.path === Routes.Root ||
          _to.path === Routes.Login ||
          _to.path.length === 0
            ? Routes.Dashboard
            : null
        console.log('to go: ', _to)
        console.log('the path to go: ', pathToGo)
        if (pathToGo) {
          return navigateTo({ path: pathToGo })
        }
      } else {
        const pathToGo: string | null =
          _to.path === Routes.Login ? null : Routes.Login
        if (pathToGo) {
          return navigateTo({ path: pathToGo })
        }
      } */
    },
    { global: false },
  )
  /* addRouteMiddleware('named-test', (_to, _from) => {
  console.log('this named middleware was added in a plugin')
}); */
})
