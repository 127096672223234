import { default as indexi505hxz1pNMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/brands/index.vue?macro=true";
import { default as indexUO4gTW64OCMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/companies/index.vue?macro=true";
import { default as companiesfDtM5bqvcwMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/customers/[id]/companies.vue?macro=true";
import { default as _91id_93jWOdy22J07Meta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/customers/companies/[id].vue?macro=true";
import { default as indextCSkv68MfkMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/customers/companies/index.vue?macro=true";
import { default as index2MUHHltZAbMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/customers/index.vue?macro=true";
import { default as indexzYzxxR7bP9Meta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/dashboard/index.vue?macro=true";
import { default as forgot_45passwordpTZODeYQodMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/forgot-password.vue?macro=true";
import { default as indexNy65mkjdGYMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/index.vue?macro=true";
import { default as paymentsLYiovItf2kMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/invoices/[id]/payments.vue?macro=true";
import { default as index0yQWu5TCQ1Meta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/invoices/index.vue?macro=true";
import { default as indexxMy4YC9eOeMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/login/index.vue?macro=true";
import { default as valuesz31IwMNFrNMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/attributes/[id]/values.vue?macro=true";
import { default as indexn7GyUBecUPMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/attributes/index.vue?macro=true";
import { default as subcategoriesHPNDkoE3QsMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/categories/[id]/subcategories.vue?macro=true";
import { default as indexTiRbJYexepMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/categories/index.vue?macro=true";
import { default as indexD9a9b6t8eUMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/index.vue?macro=true";
import { default as indexiOBAplJHuzMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/users/index.vue?macro=true";
import { default as indexfVzs4OIX6SMeta } from "/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/warehouses/index.vue?macro=true";
export default [
  {
    name: indexi505hxz1pNMeta?.name ?? "brands",
    path: indexi505hxz1pNMeta?.path ?? "/brands",
    meta: indexi505hxz1pNMeta || {},
    alias: indexi505hxz1pNMeta?.alias || [],
    redirect: indexi505hxz1pNMeta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: indexUO4gTW64OCMeta?.name ?? "companies",
    path: indexUO4gTW64OCMeta?.path ?? "/companies",
    meta: indexUO4gTW64OCMeta || {},
    alias: indexUO4gTW64OCMeta?.alias || [],
    redirect: indexUO4gTW64OCMeta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/companies/index.vue").then(m => m.default || m)
  },
  {
    name: companiesfDtM5bqvcwMeta?.name ?? "customers-id-companies",
    path: companiesfDtM5bqvcwMeta?.path ?? "/customers/:id()/companies",
    meta: companiesfDtM5bqvcwMeta || {},
    alias: companiesfDtM5bqvcwMeta?.alias || [],
    redirect: companiesfDtM5bqvcwMeta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/customers/[id]/companies.vue").then(m => m.default || m)
  },
  {
    name: _91id_93jWOdy22J07Meta?.name ?? "customers-companies-id",
    path: _91id_93jWOdy22J07Meta?.path ?? "/customers/companies/:id()",
    meta: _91id_93jWOdy22J07Meta || {},
    alias: _91id_93jWOdy22J07Meta?.alias || [],
    redirect: _91id_93jWOdy22J07Meta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/customers/companies/[id].vue").then(m => m.default || m)
  },
  {
    name: indextCSkv68MfkMeta?.name ?? "customers-companies",
    path: indextCSkv68MfkMeta?.path ?? "/customers/companies",
    meta: indextCSkv68MfkMeta || {},
    alias: indextCSkv68MfkMeta?.alias || [],
    redirect: indextCSkv68MfkMeta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/customers/companies/index.vue").then(m => m.default || m)
  },
  {
    name: index2MUHHltZAbMeta?.name ?? "customers",
    path: index2MUHHltZAbMeta?.path ?? "/customers",
    meta: index2MUHHltZAbMeta || {},
    alias: index2MUHHltZAbMeta?.alias || [],
    redirect: index2MUHHltZAbMeta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexzYzxxR7bP9Meta?.name ?? "dashboard",
    path: indexzYzxxR7bP9Meta?.path ?? "/dashboard",
    meta: indexzYzxxR7bP9Meta || {},
    alias: indexzYzxxR7bP9Meta?.alias || [],
    redirect: indexzYzxxR7bP9Meta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordpTZODeYQodMeta?.name ?? "forgot-password",
    path: forgot_45passwordpTZODeYQodMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordpTZODeYQodMeta || {},
    alias: forgot_45passwordpTZODeYQodMeta?.alias || [],
    redirect: forgot_45passwordpTZODeYQodMeta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexNy65mkjdGYMeta?.name ?? "index",
    path: indexNy65mkjdGYMeta?.path ?? "/",
    meta: indexNy65mkjdGYMeta || {},
    alias: indexNy65mkjdGYMeta?.alias || [],
    redirect: indexNy65mkjdGYMeta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/index.vue").then(m => m.default || m)
  },
  {
    name: paymentsLYiovItf2kMeta?.name ?? "invoices-id-payments",
    path: paymentsLYiovItf2kMeta?.path ?? "/invoices/:id()/payments",
    meta: paymentsLYiovItf2kMeta || {},
    alias: paymentsLYiovItf2kMeta?.alias || [],
    redirect: paymentsLYiovItf2kMeta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/invoices/[id]/payments.vue").then(m => m.default || m)
  },
  {
    name: index0yQWu5TCQ1Meta?.name ?? "invoices",
    path: index0yQWu5TCQ1Meta?.path ?? "/invoices",
    meta: index0yQWu5TCQ1Meta || {},
    alias: index0yQWu5TCQ1Meta?.alias || [],
    redirect: index0yQWu5TCQ1Meta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: indexxMy4YC9eOeMeta?.name ?? "login",
    path: indexxMy4YC9eOeMeta?.path ?? "/login",
    meta: indexxMy4YC9eOeMeta || {},
    alias: indexxMy4YC9eOeMeta?.alias || [],
    redirect: indexxMy4YC9eOeMeta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: valuesz31IwMNFrNMeta?.name ?? "products-attributes-id-values",
    path: valuesz31IwMNFrNMeta?.path ?? "/products/attributes/:id()/values",
    meta: valuesz31IwMNFrNMeta || {},
    alias: valuesz31IwMNFrNMeta?.alias || [],
    redirect: valuesz31IwMNFrNMeta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/attributes/[id]/values.vue").then(m => m.default || m)
  },
  {
    name: indexn7GyUBecUPMeta?.name ?? "products-attributes",
    path: indexn7GyUBecUPMeta?.path ?? "/products/attributes",
    meta: indexn7GyUBecUPMeta || {},
    alias: indexn7GyUBecUPMeta?.alias || [],
    redirect: indexn7GyUBecUPMeta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/attributes/index.vue").then(m => m.default || m)
  },
  {
    name: subcategoriesHPNDkoE3QsMeta?.name ?? "products-categories-id-subcategories",
    path: subcategoriesHPNDkoE3QsMeta?.path ?? "/products/categories/:id()/subcategories",
    meta: subcategoriesHPNDkoE3QsMeta || {},
    alias: subcategoriesHPNDkoE3QsMeta?.alias || [],
    redirect: subcategoriesHPNDkoE3QsMeta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/categories/[id]/subcategories.vue").then(m => m.default || m)
  },
  {
    name: indexTiRbJYexepMeta?.name ?? "products-categories",
    path: indexTiRbJYexepMeta?.path ?? "/products/categories",
    meta: indexTiRbJYexepMeta || {},
    alias: indexTiRbJYexepMeta?.alias || [],
    redirect: indexTiRbJYexepMeta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexD9a9b6t8eUMeta?.name ?? "products",
    path: indexD9a9b6t8eUMeta?.path ?? "/products",
    meta: indexD9a9b6t8eUMeta || {},
    alias: indexD9a9b6t8eUMeta?.alias || [],
    redirect: indexD9a9b6t8eUMeta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexiOBAplJHuzMeta?.name ?? "users",
    path: indexiOBAplJHuzMeta?.path ?? "/users",
    meta: indexiOBAplJHuzMeta || {},
    alias: indexiOBAplJHuzMeta?.alias || [],
    redirect: indexiOBAplJHuzMeta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexfVzs4OIX6SMeta?.name ?? "warehouses",
    path: indexfVzs4OIX6SMeta?.path ?? "/warehouses",
    meta: indexfVzs4OIX6SMeta || {},
    alias: indexfVzs4OIX6SMeta?.alias || [],
    redirect: indexfVzs4OIX6SMeta?.redirect,
    component: () => import("/home/runner/work/stocktaking-front-end/stocktaking-front-end/pages/warehouses/index.vue").then(m => m.default || m)
  }
]