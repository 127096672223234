import HttpFactory from '~/repository/factory'
import { type IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import removeEmpty from '~/helpers/helpers'
import { INVOICE_GET_BY_ID, INVOICES, INVOICES_GENERATE } from '~/constants/apiPaths/invoices/paths'
import { type IInvoicePayload } from '~/store/invoices/types/invoiceTypes'
import { type ISearchableInvoiceFields } from '~/repository/modules/invoices/types/invoiceTypes'

class InvoiceModule extends HttpFactory {
  async getAll(
    extras: IExtrasFetch,
    searchCriteria: ISearchableInvoiceFields,
    signal: AbortSignal | null = null,
  ): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>(
      'GET',
      `${this.hostname}${INVOICES}?${new URLSearchParams(urlParams)}`,
      null,
      extras,
      signal
    )
  }

  async generateInvoice(
    extras: IExtrasFetch,
    searchCriteria: object,
  ): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>(
      'GET',
      `${this.hostname}${INVOICES}${INVOICES_GENERATE}?${new URLSearchParams(urlParams)}`,
      null,
      extras,
    )
  }

  async create(
    invoice: IInvoicePayload,
    extras: IExtrasFetch,
  ): Promise<object> {
    // @ts-ignore
    delete invoice.Id

    return await this.call<object>(
      'POST',
      `${this.hostname}${INVOICES}`,
      invoice,
      extras,
    )
  }

  async getById(
    extras: IExtrasFetch,
    searchCriteria: object,
  ){
    const urlParams = removeEmpty(searchCriteria as {})
    const url = `${this.hostname}${INVOICES}${INVOICE_GET_BY_ID}?${new URLSearchParams(urlParams)}`;
    console.log(url);
    const response = await this.call<object>(
      'GET',
      url,
      null,
      extras,
    )
    return response;
  }

  async update(
    invoice: IInvoicePayload,
    extras: IExtrasFetch,
  ): Promise<object> {
    return await this.call<object>(
      'PUT',
      `${this.hostname}${INVOICES}`,
      invoice,
      extras,
    )
  }

  async delete(extras: IExtrasFetch, invoiceId: string): Promise<object> {
    return await this.call<object>(
      'DELETE',
      `${this.hostname}${INVOICES}`,
      {
        Id: parseInt(invoiceId),
      },
      extras,
    )
  }
}
export default InvoiceModule
